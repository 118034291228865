define("discourse/plugins/chat/discourse/routes/chat", ["exports", "@ember/runloop", "@ember/service", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/mixins/scroll-top", "discourse/routes/discourse", "discourse-i18n", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode", "discourse/plugins/chat/discourse/lib/init-sidebar-state"], function (_exports, _runloop, _service, _pluginApi, _utilities, _scrollTop, _discourse, _discourseI18n, _getUserChatSeparateSidebarMode, _initSidebarState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatRoute = _exports.default = (_class = class ChatRoute extends _discourse.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
    }
    titleToken() {
      return _discourseI18n.default.t("chat.title_capitalized");
    }
    beforeModel(transition) {
      if (!this.chat.userCanChat) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
      const INTERCEPTABLE_ROUTES = ["chat.channel", "chat.direct-messages", "chat.channels", "chat.threads", "chat.channel.thread", "chat.channel.thread.index", "chat.channel.thread.near-message", "chat.channel.near-message-with-thread", "chat.channel.threads", "chat.channel.index", "chat.channel.near-message", "chat.channel-legacy", "chat.channel.info.settings", "chat.channel.info.members", "chat", "chat.index"];
      if (transition.from &&
      // don't intercept when directly loading chat
      this.chatStateManager.isDrawerPreferred && INTERCEPTABLE_ROUTES.includes(transition.targetName)) {
        transition.abort();
        let url = transition.intent.url;
        if (transition.targetName.startsWith("chat.channel")) {
          url ??= this.router.urlFor(transition.targetName, ...transition.intent.contexts);
        } else {
          url ??= this.router.urlFor(transition.targetName);
        }
        this.appEvents.trigger("chat:open-url", url);
        return;
      }
      this.appEvents.trigger("chat:toggle-close");
    }
    activate() {
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        api.setSidebarPanel(_initSidebarState.CHAT_PANEL);
        const chatSeparateSidebarMode = (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser);
        if (chatSeparateSidebarMode.never) {
          api.setCombinedSidebarMode();
          api.hideSidebarSwitchPanelButtons();
        } else {
          api.setSeparatedSidebarMode();
        }
      });
      this.chatStateManager.storeAppURL();
      this.chat.updatePresence();
      (0, _runloop.schedule)("afterRender", () => {
        document.body.classList.add("has-full-page-chat");
        document.documentElement.classList.add("has-full-page-chat");
        (0, _scrollTop.scrollTop)();
      });
    }
    deactivate(transition) {
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        (0, _initSidebarState.initSidebarState)(api, this.currentUser);
      });
      if (transition) {
        let url = this.router.urlFor(transition.from.name);
        if (this.router.rootURL !== "/") {
          url = url.replace(new RegExp(`^${this.router.rootURL}`), "/");
        }
        this.chatStateManager.storeChatURL(url);
      }
      this.chat.activeChannel = null;
      this.chat.updatePresence();
      (0, _runloop.schedule)("afterRender", () => {
        document.body.classList.remove("has-full-page-chat");
        document.documentElement.classList.remove("has-full-page-chat");
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});